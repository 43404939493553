import React, { useState, useEffect } from "react"
import gql from "../../api/gql";
import {Input, Select, Tooltip} from "antd";

export default function GetProductLikeSku(props)
{
    let SKU = props?.sku ?? null;
    SKU = SKU?.split('-')?.[0]

    const [state, setState] = useState({
        products: [],
        total: 0,
        loading: true,
        productId: null,
        text: SKU,
    });

    const query = `query products($filter: ProductFilter){
      products(filter: $filter){
        count
        hits{
          id
          sku
          title
          images
          created_at
          personalized
          productStore {
            id
            site_id
            origin_id
            permalink
            site {
              title
              domain
            }
          }
          variants{
            sku
            design_status
            product_type_id
            print_files{
              title
              width
              height
              file_id
            }
          }
          ideaId
          short_link
        }
      }
    }`

    useEffect(() => {
        if (SKU != null) {
            fetchProduct(SKU)
        }
    }, [SKU])

    const fetchProduct = (text) => {
        setState({...state, loading: true })

        gql.request(query, {
            filter: {
                offset: 0,
                limit: 20,
                search: text,
                site_ids: [parseInt(localStorage.getItem('currentSiteID'))],
                product_type_ids: null
            },
        })
        .then((res) => {
            setState({...state,
                products: res.products.hits || [],
                total: res.products.count,
                loading: false,
            })

        })
        .catch((err) => {
            setState({...state, loading: false })
        })
    }

    const handleOnChange = (val) => {
        setState({...state, productId: val});

        if (props.onValueChange) {
            props.onValueChange(val)
        }
    }

    const inputOnChange = (e) => {
        setState({...state, text: e.target.value})
    }

    const searchProduct = (e) => {
        fetchProduct(e.target.value)
    }

    return (
        <div>
            <Tooltip title="Enter to filter product">
                <Input
                    onChange={inputOnChange}
                    onPressEnter={searchProduct}
                    placeholder={`Search ${state.text} ...`}
                    value={state.text}
                />
            </Tooltip>

            <div><b>Mapping Product</b></div>

            <Select
                style={{ width: "600px", maxWidth: "600px" }}
                allowClear={true}
                onChange={handleOnChange}
                value={state.productId}
                loading={state.loading}
            >
                {state.products?.map((product) => (
                    <Select.Option key={product.id}>
                        {product.title}
                    </Select.Option>
                ))}
            </Select>
        </div>   
    )
}